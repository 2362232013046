import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "typeface-abhaya-libre"

import { ThemeProvider } from "styled-components"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles"

import CssBaseline from "@material-ui/core/CssBaseline"

import Header from "./header"
import Footer from "./footer"

import Container from "@material-ui/core/Container"

const breakpoints = createBreakpoints({})
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#EB5757",
    },
  },
  typography: {
    fontFamily: "Abhaya Libre, serif",

    h1: {
      fontSize: "5rem",
      fontWeight: "800",
      marginBottom: "50px",
    },
    body1: {
      fontSize: "2rem",
      fontWeight: "400",
      marginBottom: "20px",
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          height: "100%",
          fontSize: "10px",
        },
        body: {
          height: "100%",
          fontFamily: "Abhaya Libre, serif",
          fontSize: "1.6rem",
          lineHeight: "1.5",
          color: "#4f4f4f",
          backgroundColor: "#ffffff",
        },
        "#___gatsby": {
          height: "100%",
        },
        "#gatsby-focus-wrapper": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
        main: {
          flex: 1,
          padding: "60px 0 65px",
          [breakpoints.up("md")]: {
            padding: "110px 0 115px",
          },
        },
        p: {
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: "none",
        borderBottom: "1px solid rgba(0, 0, 0, .2)",
      },
    },
    MuiTypography: {
      h1: {
        fontSize: "5rem",
        fontWeight: "800",
        color: "#000000",
      },
      h2: {
        fontSize: "4rem",
        fontWeight: "400",
        color: "#000000",
      },
    },
    MuiButton: {
      root: {
        color: "#000000",
        whiteSpace: "nowrap",
      },
      outlined: {
        border: "1px solid #000000",
        fontWeight: 600,
      },
      outlinedSizeLarge: {
        padding: "10px 20px 8px",
        fontSize: "1.6rem",
      },
    },
    MuiFormLabel: {
      root: {
        marginBottom: "5px",
        fontSize: "1.6rem",
        fontWeight: 600,
        color: "#362826",
        textTransform: "uppercase",
        "&$focused": {
          color: "initial",
        },
      },
    },
    MuiInputBase: {
      root: {
        marginBottom: "0",
      },
      input: {
        padding: "15px 24px 14px",
        border: "1px solid #000000",
        borderRadius: 0,
        fontSize: "1.6rem",
        fontWeight: 400,
        color: "#362826",
        "&::placeholder": {
          color: "#828282",
        },
      },
      inputMultiline: {
        padding: "15px 24px 14px",
        border: "1px solid #000000",
        borderRadius: 0,
        fontSize: "1.6rem",
        fontWeight: 400,
        color: "#362826",
      },
    },
    MuiInput: {
      formControl: {
        marginTop: "0 !important",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
        padding: "0 10px 16px",
      },
    },
  },
})

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header siteTitle={data.site.siteMetadata.title} />
        <Container component="main">{children}</Container>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
