import PropTypes from "prop-types"
import React from "react"

import logoWhiteImage from "../images/logo-white.svg"

import styled from "styled-components"

import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"

const FooterTag = styled("footer")`
  background-color: #000000;
  color: #ffffff;
  img {
    max-width: 130px;
    margin-bottom: 6px;
  }
  p {
    font-size: 1.1rem;
    font-weight: 500;
    color: #f2f2f2;
  }
`
const Footer = ({ siteTitle }) => {
  return (
    <FooterTag>
      <Container>
        <Box py={7}>
          <img
            src={logoWhiteImage}
            alt={`${siteTitle} Logo`}
            role="presentation"
          />
          <p>
            © Copyright {new Date().getFullYear()} {siteTitle}. All rights
            reserved.
          </p>
        </Box>
      </Container>
    </FooterTag>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
